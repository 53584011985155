import React, { FC, useEffect } from 'react'
import { BrowserRouter, Route, Switch, useHistory } from 'react-router-dom'
import { ConfiguredAuth0Provider } from './context/Auth0'
import ProtectedRoute from './components/ProtectedRoute'
import { HomePage } from './pages/HomePage'
import { Compose } from './components/Compose'
import { DashboardPage } from './pages/DashboardPage'
import { BillingClientProvider } from './context/BillingClient'
import { ServerManagerClientProvider } from './context/ServerManagerClient'
import { ConfiguredQueryClientProvider } from './context/QueryClient'
import { useAuth0 } from '@auth0/auth0-react'
import ReactGA from 'react-ga4'
import { CreateServerPage } from './pages/CreateServerPage/CreateServerPage'
import { RecoilRoot } from 'recoil'
import { CustomersClientProvider } from 'context/CustomersClient'
import { AdminClientProvider } from 'context/AdminClient'
import { SharePage } from './pages/SharePage'
import { HowToConnectIPPage } from 'pages/HowToConnectIPPage'

export const Paths = {
  home: '/' as const,
  dashboard: '/dashboard' as const,
  createServer: {
    all: '/server/new' as const,
    start: '/server/new/1' as const,
    end: '/server/new/2' as const,
  } as const,
  share: '/share/:serverId' as const,
  ipConnect: '/help/ip-connect' as const,
  shareId: (serverId: string) => `/share/${serverId}` as const,
}

const TrackUser: FC = ({ children }) => {
  const { user } = useAuth0()

  const history = useHistory()

  useEffect(() => {
    ReactGA.set({ page: window.location.pathname })
    ReactGA.send('pageview')
    const unregister = history.listen((location) => {
      ReactGA.set({ page: location.pathname })
      ReactGA.send('pageview')
    })

    return unregister
  }, [history])

  useEffect(() => {
    if (user?.sub == null) return

    ReactGA.set({ userId: user.sub })
  }, [user])

  return <>{children}</>
}

function App() {
  return (
  <>
    <h1>junimohost</h1>
    <h3>stardew server in a box</h3>
    <br />
    <ul>
      <li><a href="https://github.com/JunimoHost/junimohost-stardew-server">github</a></li>
      <li><a href="https://discord.gg/w23GVXdSF7">discord</a></li>
      <li><a href="https://junimohost.com/docs/how-does-a-stardew-server-work">documentation</a></li>
    </ul>
    <br />
    <p>I open sourced the code and refunded everyone's last month in full. If you'd like a copy of your save, DM me (@mrthinger) the email you signed up with in discord.</p>
    <br />
    <p>1.6 broke the server and I don't have time to maintain it anymore. The server is going to be tricky to use in its current state and may require me to dig out some code I haven't put up. If thats the case, ping me, @mrthinger, in discord.</p>
    <br />
    <br />
    <p>I love this community. Thank you for all the support over the years. It was awesome.</p>
    <p>I am building <a href="https://kymyth.com">https://kymyth.com</a> now. Its going to be the next generation of tooling for machine learning research and hopefully help us figure out some of the worlds most difficult problems.</p>
  </>
 

    // <Compose
    //   components={[
    //     RecoilRoot,
    //     ConfiguredQueryClientProvider,
    //     BrowserRouter,
    //     ConfiguredAuth0Provider,
    //     BillingClientProvider,
    //     ServerManagerClientProvider,
    //     AdminClientProvider,
    //     CustomersClientProvider,
    //     TrackUser,
    //   ]}
    // >
    //   <Switch>
    //     <ProtectedRoute path={Paths.dashboard} component={DashboardPage} />
    //     <Route path={Paths.ipConnect} component={HowToConnectIPPage} />
    //     <Route path={Paths.share} component={SharePage} />
    //     <Route path={Paths.createServer.all} component={CreateServerPage} />
    //     <Route path="/" component={HomePage} />
    //   </Switch>
    // </Compose>
  )
}

export default App
