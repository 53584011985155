import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import ReactGA from 'react-ga4'

ReactGA.initialize('G-65QLHX6H7G', {
  testMode: process.env.NODE_ENV !== 'production',
})
ReactGA._gtag('config', 'UA-208068636-1')

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
